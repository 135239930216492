import { useCallback } from "react";
import { request } from "../../../utils/request";
import { useSelector } from "react-redux";
import { selectRestaurantId } from "../../../pages/main/selectors";
import { API } from "../../../pages/main/constants";

export default function useBrainTreeCheckoutHandler() {
  const restaurantId = useSelector(selectRestaurantId);

  const checkout = useCallback(
    async ({ nonce, amount }) => {
      try {
        const res = await request(
          API.GET_BRAIN_TREE_CHECKOUT,
          { restaurantId, nonce, amount },
          false
        );
        return res.data;
      } catch (error) {}
    },
    [restaurantId]
  );
  return { checkout };
}
