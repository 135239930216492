import React, { useRef, useEffect, useCallback, useState } from "react";
import "./styles.scss";
import { loadScript } from "@paypal/paypal-js";
import {
  selectPaypalClientId,
  selectRestaurantCurrency,
  selectCartData,
} from "../../pages/main/selectors";
import { useSelector } from "react-redux";
import { generateDescription } from "../CheckoutModal/functions";
import { useTranslation } from "react-i18next";

export default function PaypalButton({ onSuccess }) {
  const paypalRef = useRef();
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const paypalClientId = useSelector(selectPaypalClientId);
  const { t } = useTranslation();
  const currency = useSelector(selectRestaurantCurrency);
  const cartData = useSelector(selectCartData);
  useEffect(() => {
    if (cartData) {
      setDescription(
        generateDescription({
          cartData,
          lan: "en",
          currency,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData]);
  const renderPaypal = useCallback(async () => {
    try {
      if (description && cartData.items.length > 0) {
        await paypalRef.current
          .Buttons({
            createOrder: (data, action, error) => {
              return action.order.create({
                intent: "CAPTURE",
                purchase_units: [
                  {
                    items: description,
                    amount: {
                      currency_code: currency,
                      value: cartData.total,
                      breakdown: {
                        item_total: {
                          value: parseFloat(cartData.total),
                          currency_code: currency,
                        },
                      },
                    },
                  },
                ],
              });
            },
            onApprove: async (data, action) => {
              const order = await action.order.capture();
              if (order) {
                onSuccess({ orderId: data.orderID });
                // dispatch(actions.removeCartItems());
              }
            },
            onError: (err) => {
              setLoading(false);
            },
          })
          .render("#paypal-button-container")
          .then(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.error("failed to render the PayPal Buttons", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData, description]);
  const initiatePaypal = useCallback(async () => {
    try {
      paypalRef.current = await loadScript({
        "client-id": paypalClientId,
        currency: currency || "USD",
      });
      renderPaypal();
    } catch (error) {
      console.log("failed to initiate paypal");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData, description]);

  useEffect(() => {
    if (
      cartData &&
      cartData.items &&
      cartData.items.length > 0 &&
      description
    ) {
      initiatePaypal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData, description]);
  return (
    <div className="paypal-button-wrapper">
      {loading ? <p>{t("cart.initializing")}</p> : null}
      <div id="paypal-button-container"></div>
      {error && <p>{error}</p>}
    </div>
  );
}
