import { call, put, select, takeLatest } from "redux-saga/effects";
import moment from "moment";
import { API } from "./constants";
import { mainActions as actions } from "./actions";
import { request } from "../../utils/request";
import { selectRestaurantId, selectRestaurantUrl } from "./selectors";
import { selectSelectedStore } from "../menuPage/selectors";
import configs from "../../configs";
import { orderAcknowledge } from "../../socket/socketCtrl";

function* getRestaurantData(action) {
  try {
    const result = yield call(
      request,
      API.GET_RESTURANT_DATA,
      {
        restaurantUrl: action.payload.restaurantUrl,
        table: action.payload.table,
      },
      false
    ) || {};
    if (result.success) {
      yield put(
        actions.getRestaurantDataSucceeded({
          ...result.data.restaurantData,
          table: result.data?.table,
        })
      );
      yield put(
        actions.setWhiteLabelEnabledStatus(result.data.whitelabelEnabled)
      );
    } else {
      yield put(actions.getRestaurantDataFailed(result.msg));
      if (result.error.newUrl) {
        window.location.replace(result.error.newUrl);
        if (result.error.store_limit_id) {
          localStorage.setItem(
            `store_limit_id-${configs.APP_DOMAIN}`,
            result.error.store_limit_id
          );
        } else {
          localStorage.removeItem(`store_limit_id-${configs.APP_DOMAIN}`);
        }
      } else {
        // window.location.replace(window.location.origin + "/not-found");
      }
    }
  } catch (e) {
    yield put(actions.getRestaurantDataFailed(e.message));
  }
}
function* register(action) {
  try {
    const restaurantId = yield select(selectRestaurantId);
    const result = yield call(
      request,
      API.REGISTER,
      { ...action.payload, restaurantId },
      false
    ) || {};
    if (result.success) {
      localStorage.setItem(`token-${configs.APP_DOMAIN}`, result.data.token);
      yield put(actions.registerSucceeded(result.data));
    } else {
      yield put(actions.registerFailed(result.error.message));
    }
  } catch (e) {
    yield put(actions.registerFailed(e.message));
  }
}
function* getCurrentUser(action) {
  try {
    const result = yield call(
      request,
      API.GET_CURRENT_USER,
      { id: action.payload.id },
      true
    ) || {};
    if (result.success) {
      yield put(actions.getCurrentUserSucceeded(result.data));
    } else {
      yield put(actions.getCurrentUserFailed(result.error.message));
    }
  } catch (e) {
    console.log("error >>>>", e);
    yield put(actions.getCurrentUserFailed(e.message));
  }
}
function* login(action) {
  try {
    const result = yield call(
      request,
      API.LOGIN,
      { ...action.payload },
      false
    ) || {};
    if (result.success) {
      localStorage.setItem(`token-${configs.APP_DOMAIN}`, result.data.token);
      yield put(actions.loginSucceeded(result.data));
    } else {
      yield put(actions.loginFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.loginFailed(e.message));
  }
}
function* saveOrder(action) {
  try {
    const restaurantUrl = yield select(selectRestaurantUrl);

    const startOfDay = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
    const endOfDay = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

    const result = yield call(
      request,
      API.SAVE_ORDER,
      { ...action.payload, startOfDay, endOfDay },
      false
    ) || {};
    if (result.success) {
      yield put(actions.saveOrderSucceeded(result.data));
      orderAcknowledge({ room: restaurantUrl, orderId: result.data._id });
    } else {
      yield put(actions.saveOrderFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.saveOrderFailed(e.message));
  }
}

function* editOrder(action) {
  try {
    // const restaurantUrl = yield select(selectRestaurantUrl);
    const result = yield call(
      request,
      API.EDIT_ORDER,
      { ...action.payload },
      false
    ) || {};
    if (result.success) {
      yield put(actions.editOrderSucceeded(result.data));
    } else {
      yield put(actions.editOrderFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.editOrderFailed(e.message));
  }
}

function* fetchExistingOrders(action) {
  try {
    const result = yield call(
      request,
      API.FETCH_ORDERS,
      { ...action.payload },
      false
    ) || {};
    if (result.success) {
      yield put(actions.fetchExistingOrdersSucceeded(result.data));
    } else {
      yield put(actions.fetchExistingOrdersFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.fetchExistingOrdersFailed(e.message));
  }
}
function* sendResetPasswordLink(action) {
  try {
    const result = yield call(
      request,
      API.SEND_RESET_PASSWORD_LINK,
      { ...action.payload },
      false
    ) || {};
    if (result.success) {
      yield put(actions.sendResetPasswordLinkSucceeded(result.data));
    } else {
      yield put(actions.sendResetPasswordLinkFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.sendResetPasswordLinkFailed(e.message));
  }
}
function* resetPassword(action) {
  try {
    const result = yield call(
      request,
      API.RESET_PASSWORD,
      { ...action.payload },
      true
    ) || {};
    if (result.success) {
      yield put(actions.resetPasswordSucceeded(result.data));
      localStorage.removeItem(`token-${configs.APP_DOMAIN}`);
    } else {
      yield put(actions.resetPasswordFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.resetPasswordFailed(e.message));
  }
}
function* getCurrentPromotionsWorker(action) {
  try {
    const result = yield call(
      request,
      API.GET_CURRENT_PROMOTIONS,
      {
        restaurantId: action.payload.restaurantId,
        storeId: action.payload.storeId,
        orderType: action.payload.orderType,
        currentTime: new Date().toISOString(),
      },
      false
    ) || {};
    if (result.success) {
      yield put(actions.getCurrentPromotionsSucceeded(result.data));
    } else {
      yield put(actions.getCurrentPromotionsFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getCurrentPromotionsFailed(e.message));
  }
}
function* getSubscriptionStatusWorker(action) {
  try {
    const result = yield call(
      request,
      API.GET_SUBSCTIPTION,
      {
        ...action.payload,
      },
      false
    ) || {};
    if (result.success) {
      yield put(actions.getSubscriptionStatusSucceeded(result.data));
    } else {
      yield put(actions.getSubscriptionStatusFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getSubscriptionStatusFailed(e.message));
  }
}
function* getStripeCheckoutSession(action) {
  try {
    const result = yield call(
      request,
      API.GET_STRIPE_PAYMENT_PAGE,
      action.payload,
      false
    ) || {};
    if (result.success) {
      yield put(actions.getStripeCheckoutSessionSucceeded(result.data));
    } else {
      console.log("this is error", result.error);
      yield put(
        actions.getStripeCheckoutSessionFailed(
          result.error.raw.message || result.msg
        )
      );
    }
  } catch (e) {
    console.log(e);
    yield put(actions.getStripeCheckoutSessionFailed(e.message));
  }
}
function* getPaymentIntentDetails(action) {
  try {
    const result = yield call(
      request,
      API.GET_PAYMENT_INTENT_DETAILS,
      { ...action.payload },
      false
    ) || {};
    if (result.success) {
      yield put(actions.getPaymentIntentDetailsSucceeded(result.data));
    } else {
      yield put(actions.getPaymentIntentDetailsFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getPaymentIntentDetailsFailed(e.message));
  }
}
function* getFonts(action) {
  try {
    const result = yield call(
      request,
      API.GET_FONTS,
      { ...action.payload },
      false
    ) || {};
    if (result.success) {
      yield put(actions.getFontsSucceeded(result.data));
    } else {
      yield put(actions.getFontsFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getFontsFailed(e.message));
  }
}
function* receiveHotActions() {
  const restaurantId = yield select(selectRestaurantId);
  const store = yield select(selectSelectedStore);

  try {
    const result = yield call(
      request,
      API.GET_HOT_ACTIONS,
      { restaurantId, storeId: store._id },
      true
    ) || {};

    if (result.success) {
      yield put(actions.receiveHotActionsSucceeded(result.data));
    } else {
      yield put(actions.receiveHotActionsFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.receiveHotActionsFailed(e.message));
  }
}
function* getAdyenPaymentMethodsGenerator(action) {
  try {
    const result = yield call(
      request,
      API.GET_ADYEN_PAYMENT_METHODS,
      { ...action.payload },
      false
    ) || {};

    if (result.success) {
      yield put(actions.getAdyenPaymentMethodsSucceeded(result.data));
    } else {
      yield put(actions.getAdyenPaymentMethodsFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getAdyenPaymentMethodsFailed(e.message));
  }
}
function* getAdyenPaymentDetailsGenerator(action) {
  try {
    const result = yield call(
      request,
      API.GET_ADYEN_PAYMENT_DETAILS,
      { ...action.payload },
      false
    ) || {};

    if (result.success) {
      yield put(actions.getAdyenPaymentDetailsSucceeded(result.data));
    } else {
      yield put(actions.getAdyenPaymentDetailsFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getAdyenPaymentDetailsFailed(e.message));
  }
}
function* getBrainTreeClientTokensGenerator(action) {
  try {
    const result = yield call(
      request,
      API.GET_BRAIN_TREE_CLIENT_TOKEN,
      { ...action.payload },
      false
    ) || {};

    if (result.success) {
      yield put(
        actions.getBrainTreeClientTokenSucceeded(result.data.clientToken)
      );
    } else {
      yield put(actions.getBrainTreeClientTokenFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getBrainTreeClientTokenFailed(e.message));
  }
}
function* mainSaga() {
  yield takeLatest(actions.getRestaurantData.type, getRestaurantData);
  yield takeLatest(actions.register.type, register);
  yield takeLatest(actions.login.type, login);
  yield takeLatest(actions.getCurrentUser.type, getCurrentUser);
  yield takeLatest(actions.saveOrder.type, saveOrder);
  yield takeLatest(actions.editOrder.type, editOrder);
  yield takeLatest(actions.fetchExistingOrders.type, fetchExistingOrders);
  yield takeLatest(actions.sendResetPasswordLink.type, sendResetPasswordLink);
  yield takeLatest(actions.resetPassword.type, resetPassword);
  yield takeLatest(
    actions.getCurrentPromotions.type,
    getCurrentPromotionsWorker
  );
  yield takeLatest(
    actions.getSubscriptionStatus.type,
    getSubscriptionStatusWorker
  );
  yield takeLatest(
    actions.getStripeCheckoutSession.type,
    getStripeCheckoutSession
  );
  yield takeLatest(
    actions.getPaymentIntentDetails.type,
    getPaymentIntentDetails
  );
  yield takeLatest(actions.getFonts.type, getFonts);
  yield takeLatest(actions.receiveHotActions.type, receiveHotActions);
  yield takeLatest(
    actions.getAdyenPaymentMethods.type,
    getAdyenPaymentMethodsGenerator
  );
  yield takeLatest(
    actions.getAdyenPaymentDetails.type,
    getAdyenPaymentDetailsGenerator
  );
  yield takeLatest(
    actions.getBrainTreeClientToken.type,
    getBrainTreeClientTokensGenerator
  );
}

export default mainSaga;
