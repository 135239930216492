/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import dropin from "braintree-web-drop-in";

import { useDispatch, useSelector } from "react-redux";
import { useMainSlice } from "../../../pages/main/actions";
import {
  selectBrainTreeToken,
  selectCartData,
  selectIsBrainTreeLoading,
  selectOrderSavingInProgress,
  selectRestaurantCurrency,
  selectRestaurantId,
} from "../../../pages/main/selectors";
import { useTranslation } from "react-i18next";
import ButtonCirculerLoader from "../../ButtonCirculerLoader";

import useBrainTreeCheckoutHandler from "../hooks/useBrainTreeCheckoutHandler";

export default function BrainTreePaymentButton({ onSuccess }) {
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  const { actions } = useMainSlice();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [isCheckoutInProgress, setCheckoutInProgress] = useState(false);
  const restaurantId = useSelector(selectRestaurantId);
  const isBrainTreeLoading = useSelector(selectIsBrainTreeLoading);
  const clientToken = useSelector(selectBrainTreeToken);
  const orderSaveInprogress = useSelector(selectOrderSavingInProgress);
  const cartData = useSelector(selectCartData);
  const currency = useSelector(selectRestaurantCurrency);
  const { t } = useTranslation();
  const { checkout } = useBrainTreeCheckoutHandler();

  const getClientToken = useCallback(() => {
    dispatch(actions.getBrainTreeClientToken({ restaurantId }));
  }, [restaurantId]);

  useEffect(() => {
    getClientToken();
  }, []);

  useEffect(() => {
    if (clientToken) {
      const initializeBraintree = () =>
        dropin.create(
          {
            // insert your tokenization key or client token here
            authorization: clientToken,
            container: "#braintree-drop-in-div",
            googlePay: {
              googlePayVersion: 2,
              merchantId: "your_google_merchant_id",
              transactionInfo: {
                totalPriceStatus: "FINAL",
                totalPrice: cartData.total, // Set your transaction price
                currencyCode: currency || "USD",
              },
            },
            venmo: {
              allowNewBrowserTab: false, // Optional: set to true if Venmo App Switch is enabled
            },
            paypal: {
              flow: "checkout", // Optional: 'vault' for one-time payments
            },
          },
          async function (error, instance) {
            if (error) {
              console.error(error);
              setError(error.message);
            } else {
              setBraintreeInstance(instance);
            }
          }
        );

      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }
  }, [clientToken]);
  return (
    <>
      <div
        id={"braintree-drop-in-div"}
        style={{ hidden: isBrainTreeLoading }}
      />
      {error && (
        <p>
          {t("cart.braintree-payment-failed-message", {
            error,
          })}
        </p>
      )}
      <button
        className="main-button"
        nonce
        type="primary"
        hidden={isBrainTreeLoading}
        disabled={
          !braintreeInstance ||
          isBrainTreeLoading ||
          isCheckoutInProgress ||
          orderSaveInprogress
        }
        onClick={async () => {
          setError(null);
          if (braintreeInstance) {
            braintreeInstance.requestPaymentMethod(async (error, payload) => {
              if (error) {
                console.error(error);
              } else {
                const paymentMethodNonce = payload.nonce;
                setCheckoutInProgress(true);
                const result = await checkout({
                  nonce: paymentMethodNonce,
                  amount: cartData.total,
                });
                if (result.success) {
                  onSuccess({ orderId: result.transaction.id });
                } else {
                  setError(result.message);
                }
                setCheckoutInProgress(false);
              }
            });
          }
        }}
      >
        {t("cart.confirm")}{" "}
        {(isBrainTreeLoading ||
          isCheckoutInProgress ||
          orderSaveInprogress) && <ButtonCirculerLoader />}
      </button>
      {isBrainTreeLoading && <p>{t("cart.initializing")}</p>}
    </>
  );
}
